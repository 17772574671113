/* :root {

  
  --neon-text-color: #f40;
  --neon-border-color: #08f;
}
 */

body {
  height: 100%;
}

 
footer { 
 /*  position: absolute;
  bottom: 0px;
  width: 100%; */
  color: #ffffff;
  font-size: 16px; 
}

footer * {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  border: none;
  outline: none;
}

.row {
  padding: 1em 1em;
}

.copyright {
  /* position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0.3em 1em; */
  background-color: #25262e;
}

.copyright p {
  font-size: 0.9em;
  text-align: center;
}  

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

.nftapp {
  background: radial-gradient(circle at 50% 50%, #5e99ac, #508aa3, #35678f, #1f457a, #173771);
  font-size: small;
  /*   
  background: linear-gradient(270deg, #11bce7, #0c485f);
  background-size: 400% 400%;
-webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite; */
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

.walletBalance {
  color: #ffffff;
  /* background: #616161;  */
  border: #17b2ff;
  border-radius: 10px;
  White-space: nowrap;
  font-size: 11px;
  vertical-align: middle;
  padding: 8px 1px 5px 1px;
}

.accountInfo{
  background: #616161;
  border-radius: 10px;
}

.accountInfo:hover {
  border-radius: 10px;
  background: #186085;
}

.btnpayment:hover{
  box-shadow: 0px 1px 10px #17b2ff;
  border-radius: 50px;

}
  
.connectbutton { 
  font-size: 11px;
  width: 150px;
  background: #000000;
  box-shadow: 0px 1px 10px #17b2ff;
  color: #ffffff;
  border-radius: 10px;
  vertical-align: middle;
  padding: 5px;
}

.stakebutton {
  right: 0;
  top: 0;
  background: #00000010;
  box-shadow: 0px 1px 10px #b7e707;
  color: #ffffff;
  border-radius: 14px;
  padding: 5px 12px;
  font-size: large;
  font-weight: 200;
}

.nftminter {
  background: radial-gradient(circle at 50% 50%, #090f11, #0c1519, #101e2b, #0f223c, #0e2144);
  font-family: "SF Pro Display";
  font-weight: 300;
  color: white;
  box-shadow: 0 0 1px 1px #17b2ff;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 5px !important;
  -webkit-border: 20px !important;
  -moz-border: 20px !important;
  border: 20px !important;

}


.nftstaker {
  background: radial-gradient(circle at 50% 50%, #090f11, #0c1519, #101e2b, #0f223c, #0e2144);
  font-family: "SF Pro Display";
  font-weight: 300;
  color: white;
  box-shadow: 0 0 1px 1px #17b2ff;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 5px !important;
  -webkit-border: 20px !important;
  -moz-border: 20px !important;
  border: 20px !important;
}

.flex {
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: inline-flex;
  max-width: 1200px;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  flex-wrap: wrap;

}

.stakingrewards {
  font-family: "SF Pro Display";
  font-weight: 300;
  box-shadow: 1px 1px 15px #ffffff;
  background: radial-gradient(circle at 50% 50%, #000000, #050604, #0a0c08, #0f130b, #14190f, #191f13, #1e2517, #232b1b, #28321e, #2d3822, #323e26);
}

.stakingstats {
  font-family: "SF Pro Display";
  font-weight: 300;
  box-shadow: 1px 1px 15px #ffffff;
  background: radial-gradient(circle at 50% 50%, #000000, #050604, #0a0c08, #0f130b, #14190f, #191f13, #1e2517, #232b1b, #28321e, #2d3822, #323e26);
}

.modal-style1 {
  background: radial-gradient(circle at 50% 50%, #090f11, #0c1519, #101e2b, #0f223c, #0e2144);
  font-family: "SF Pro Display";
  font-weight: 300;
  color: white;
  box-shadow: 0 0 1px 1px #17b2ff;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
  -webkit-border: 20px !important;
  -moz-border: 20px !important;
  border: 20px !important;
}

.colnitroMint {
  width: 200px;
  height: 50%;
}

.nitro-qty {
  font-size: 9px;
  padding-bottom: 10px;
}

.colnftListView {
  width: 50%;
  height: 100%;
  margin-right: 0;
}


.container-style {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: stretch;
  align-items: stretch;

}

@keyframes glow2 {
  from {
    box-shadow: 0 0 4px -1px #f4dc76;
  }

  to {
    box-shadow: 0 0 10px 7px #ffb300;
  }
}

@keyframes glow1 {
  from {
    box-shadow: 0 0 4px -1px #76f476;
  }

  to {
    box-shadow: 0 0 10px 7px #15ff00;
  }
}

.Equipped0 {
  animation: equipped1 1s infinite alternate;
}

@keyframes equipped1 {
  from {
    box-shadow: 0 0 4px -1px #76f476;
  }

  to {
    box-shadow: 0 0 10px 7px #15ff00;
  }
}

.Equipped1, .Equipped5 {
  animation: equipped1 1s infinite alternate;
}

@keyframes equipped1 {
  from {
    box-shadow: 0 0 4px -1px #f4f276;
  }

  to {
    box-shadow: 0 0 10px 7px #e5ff00;
  }
}

.Equipped2,
.Equipped3 {
  animation: equipped2 1s infinite alternate;
}

@keyframes equipped2 {
  from {
    box-shadow: 0 0 4px -1px #7676f4;
  }

  to {
    box-shadow: 0 0 10px 7px #2600ff;
  }
}

/* .Equipped3{ 
  animation: equipped3 1s infinite alternate; 
}
@keyframes equipped3 {
  from { box-shadow: 0 0 4px -1px #768ff4; }
  to { box-shadow: 0 0 10px 7px #0026ff; }
} */

.Equipped4 {
  animation: equipped4 1s infinite alternate;
}

@keyframes equipped4 {
  from {
    box-shadow: 0 0 4px -1px #f47676;
  }

  to {
    box-shadow: 0 0 10px 7px #ff0000;
  }
}

/* .Equipped5{ 
  animation: equipped5 1s infinite alternate; 
}
@keyframes equipped5 {
  from { box-shadow: 0 0 4px -1px #f47676; }
  to { box-shadow: 0 0 10px 7px #ff0000; }
} */




@keyframes SelectedNFT {
  from {
    box-shadow: 0 0 4px -1px #295ffe;
  }

  to {
    box-shadow: 0 0 10px 7px #d3cfff;
  }
}


.stakegoldeffect {
  font-family: "SF Pro Display";
  font-weight: 300;
  animation: glow2 3s infinite alternate;
  background: linear-gradient(to bottom,
      rgb(1, 53, 130),
      rgba(255, 0, 0, 0));
  z-index: 9999;
}

.NitroEffect {
  font-family: "SF Pro Display";
  font-weight: 300;
  animation: glow1 1s infinite alternate;
  background: linear-gradient(to bottom,
      rgb(1, 53, 130),
      rgba(255, 0, 0, 0))
}


.SelectedNFT {
  font-family: "SF Pro Display";
  font-weight: 300;
  animation: SelectedNFT 1s infinite alternate;
  background: linear-gradient(to bottom,
      rgb(1, 53, 130),
      rgba(255, 0, 0, 0))
}

.wrapper {
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: grid;
  place-items: center;
  gap: 15px;
}

.marginright {
  margin-right: "100px";
}

.nitrocontainer {
  height: 400px;
  width: 200px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  color: #ffffff;
  background: radial-gradient(var(--theme-color-1), var(--theme-color-2));
  /* box-shadow: 0px 1px 10px #ffffff; */
}

.nftcontainer {
  height: 230px;
  width: 100px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  color: #ffffff;
  background: radial-gradient(var(--theme-color-1), var(--theme-color-2));
  box-shadow: 0px 1px 10px #ffffff;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  position: relative;
  width: 100%;
  cursor: pointer;
}

input[type="checkbox"]:after {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f111";
  font-size: 18px;
  color: #ffffff;
  right: 10px;
  top: -5px;
}



input[type="checkbox"]:checked:after {
  font-weight: 900;
  content: "\f058";
  color: #ffffff;
}

.nftcontainer img {
  width: 70%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 15%;
  cursor: pointer;

}

.nftcontainer-mobile img {
  width: 70%;
  /* position: absolute; */
  margin: auto;
  left: 0;
  right: 0;
  top: 15%;
  cursor: pointer;

}

@media screen and (min-width: 950px) {
  .wrapper {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }

  .nftcontainer {
    height: 300px;
    width: 200px;
  }

  .nftcontainer-mobile {
    height: 300px;
    width: 200px;
  }

  input[type="checkbox"]:after {
    font-size: 22px;
  }
}

.nftdetail {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5%;
  cursor: pointer;
  display: grid;
  place-items: center;
}

.errorMessage {
  height: 100px;
  width: 100px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 250px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.5px;
  cursor: pointer;
  background-color: #00000010;
  color: #000000;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 47px;
  background-position: 247px 0.5px;
  outline: none;
  box-shadow: 0 0 20px rgba(20, 20, 30, 0.25);
}

select::-ms-expand {
  display: none;
}

select option {
  background-color: #000000;
  letter-spacing: 1.2px;
  font-weight: 400;
  font-size: 18px;
}

.selected {
  display: none;
}





.vault_container:before {
  content: "";
  position: absolute;
  height: 280px;
  width: 280px;
  background: radial-gradient(var(--theme-color-1), var(--theme-color-2));
  border-radius: 50%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 50%;
}

.vault_card {
  height: 285px;
  width: 200px;
  position: relative;
  box-shadow: 0px 1px 10px #fafae6;
  border-radius: 5%;
  border: 10px solid var(--white-rgba);
}

.vault_front-mobile,
.vault_front,
.vault_back {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background-color: var(--white-rgba);
  border: 2px solid var(--white-rgba);
  backdrop-filter: blur(8px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transition: 1s;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.vault_front-mobile,
.vault_front {
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}

.vault_front img {
  height: 50%;
  width: 65%;
  border-radius: 50%;
  border: 10px solid var(--white-rgba);  
}

.vault_front-mobile img {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  border: 10px solid var(--white-rgba);  
}


.vault_details { 
  text-align: center;
}

h2 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: var(--white-shade-1);
}

.spanX {
  font-size: 14px;
  color: var(--white-shade-2);
  cursor: pointer;
}

a {
  cursor: pointer;
}

.vault_back {
  justify-content: space-around;
  transform: rotateY(180deg);
}

.vault_icon {
  display: grid;
  place-items: center;
  height: 65px;
  width: 65px;
  background-color: var(--white-rgba);
  font-size: 10px;
  border-radius: 8px;
  text-decoration: none;
  color: var(--white-shade-1);
}

.vault_icon:hover {
  background-color: var(--white-shade-1);
  color: var(--theme-color-1);
}

.vault_card:hover .vault_back {
  transform: rotateY(0deg);
  box-shadow: 0px 1px 10px #ffffff;
}

.vault_card:hover .vault_front {
  transform: rotateY(-180deg);
  box-shadow: 0px 1px 10px #ffffff;
}

.vault_card:hover .vault_front-mobile {
  transform: rotateY(-180deg);
  box-shadow: 0px 1px 10px #ffffff;
}


.h5Nitro {
  font-size: 15px;
  color: white;
}

.h6Nitro {
  font-size: 11px;
  color: white;
}

.h6nftName {
  position: absolute;
  justify-items: center;
  bottom: 55px;
}

.h6pendingEarn {
  position: absolute;
  font-size: 12px !important;
  top: 15px;
  left: 25px;
}

.h6nitroEquip {
  position: absolute;
  bottom: 36px;
}


.sidebar {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch
}

.powerUpButton {
  position: absolute;
  left: 3%;
  bottom: 3%;
  background: #00000010;
  box-shadow: 0px 1px 10px #ffffff;
  color: #ffffff;
  border-radius: 14px;
  padding: 2px 12px;
  font-size: large;
  font-weight: 200;
}

.transferButton {
  position: absolute;
  left: 3%;
  bottom: 3%;
  background: #00000010;
  box-shadow: 0px 1px 10px #ffffff;
  color: #ffffff;
  border-radius: 14px;
  padding: 2px 12px;
  font-size: large;
  font-weight: 200;
}

.nftMenuBackButton {
  background: #00000010;
  box-shadow: 0px 1px 10px #ffffff;
  color: #ffffff;
  border-radius: 14px;
  padding: 2px 12px;
  font-size: large;
  font-weight: 200;
  margin-right: 20px;
}

.nftMenuClaimButton {
  background: #00000010;
  box-shadow: 0px 1px 10px #ffffff;
  color: #ffffff;
  border-radius: 14px;
  padding: 2px 20px;
  font-size: large;
  font-weight: 200;
  margin-right: 20px;
  white-space: nowrap;
}

.nftMenuUnstakeButton {
  background: #00000010;
  box-shadow: 0px 1px 10px #ffffff;
  color: #ffffff;
  border-radius: 14px;
  padding: 2px 20px;
  font-size: large;
  font-weight: 200;
  margin-right: 20px;
  white-space: nowrap;
}

.nftMenuStakeButton {
  background: #00000010;
  box-shadow: 0px 1px 10px #ffffff;
  color: #ffffff;
  border-radius: 14px;
  padding: 2px 12px;
  font-size: large;
  font-weight: 200;
  margin-right: 20px;
}

.tab-expand {
  width: 100%;
}

.drpFilter {
  background: #00000010;
  color: #ffffff;
  border-radius: 5px;
  padding: 7px;
  width: 36%;
  font-size: 15px;
}

.hold-nft-menu {
  margin-right: 20px;
}

.buttonmenu {
  font-size: 11px;
}


/*MODAL TEST*/
/* 
.scroll {
  overflow: scroll;
  height: 400px;
  width: 200px;
  padding-left: 1px;
  padding-right: 1px;
  display: grid;
  grid-template-columns: repeat(1, 4fr);
  grid-row-gap: .5em;
  grid-column-gap: 1em;
} */

#prompt-form {
  display: inline-block;
  padding: 5px 5px 5px 70px;
  width: 200px;

  vertical-align: middle;

  padding: 3.1em 1.25em;
  text-align: center;
  background: radial-gradient(circle at 50% 50%, #5e99ac, #508aa3, #35678f, #1f457a, #173771);

  background-repeat: no-repeat;
  /* background-position: 0 0.62em;
  box-shadow: 0 0 2.5em rgba(0, 0, 0, 0.15); */
  box-shadow: 0px 1px 10px #ffffff;
  border-radius: 0.5em;
  transition: 0.5s;
  /* cursor: pointer; */


}


.transfer-width {
  width: 400px !important;
  height: 100px !important;  
}


#prompt-form-mobile {
  display: inline-block;
  padding: 5px 5px 5px 70px;
  width: 200px;

  vertical-align: middle;

  padding: 3.1em 1.25em;
  text-align: center;
  background: radial-gradient(circle at 50% 50%, #5e99ac, #508aa3, #35678f, #1f457a, #173771);

  background-repeat: no-repeat;
  background-position: 0 0.62em;
  box-shadow: 0 0 2.5em rgba(0, 0, 0, 0.15);
  border-radius: 0.5em;
  transition: 0.5s;
  cursor: pointer;


}

#prompt-form-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
}

#prompt-form-container:before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle;
}

#cover-div {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.3;
}

#prompt-form-mobile input[name="text"],
#prompt-form input[name="text"] {
  display: block;
  margin: 5px;
  width: 180px;
}

.nft_card_view {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 4fr);
  grid-row-gap: .5em;
  grid-column-gap: 1em;
}

.nft_card_view1 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 4fr) !important;
  grid-row-gap: .5em;
  grid-column-gap: 1em;
}

.nft_card_view2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 4fr) !important;
  grid-row-gap: .5em;
  grid-column-gap: 1em;
}

.nft_card_view3 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 4fr) !important;
  grid-row-gap: .5em;
  grid-column-gap: 1em;
}

.nft_card_view4 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 4fr) !important;
  grid-row-gap: .5em;
  grid-column-gap: 1em;
}

.nft_card_view5 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 4fr) !important;
  grid-row-gap: .5em;
  grid-column-gap: 1em;
}



.nft_card_view-mobile {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 4fr);
  grid-row-gap: .5em;
  grid-column-gap: 1em;
}


.nftcontainer-mobile {
  height: 330px;
  /* width: 100px; */
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  color: #ffffff;
  background: radial-gradient(var(--theme-color-1), var(--theme-color-2));
  box-shadow: 0px 1px 10px #ffffff;
}


.container-style-mobile {
  /* display: grid;
  grid-template-columns: 250px 1fr; */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: stretch;
  align-items: stretch;

}

.nftminter-mobile {
  background: radial-gradient(circle at 50% 50%, #090f11, #0c1519, #101e2b, #0f223c, #0e2144);
  font-family: "SF Pro Display";
  font-weight: 300;
  color: white;
  box-shadow: 0 0 1px 1px #17b2ff;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 5px !important;
  -webkit-border: 20px !important;
  -moz-border: 20px !important;
  border: 20px !important;

}

.fixed-float {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 979px) {
  .navbar-fixed-bottom {
    margin-top: 20px;
    bottom: 0;
    position: fixed;
  }
}

.image-mobile {
  /* height: 200px; */
  width: 40% !important;
}

.image {
  width: 150px !important;
}

.transfer-nft {
  width: 100%;
  height: 150px !important;
}


.vault_card-mobile {
  height: 285px;
  width: 100%;
  position: relative;
  box-shadow: 0px 1px 10px #fafae6;
  border-radius: 5%;
  border: 10px solid var(--white-rgba);
}

.vault_card-mobile:hover .vault_back {
  transform: rotateY(0deg);
  box-shadow: 0px 1px 10px #ffffff;
}

.vault_card-mobile:hover .vault_front {
  transform: rotateY(-180deg);
  box-shadow: 0px 1px 10px #ffffff;
}

.vault_card-mobile:hover .vault_front-mobile {
  transform: rotateY(-180deg);
  box-shadow: 0px 1px 10px #ffffff;
}

/*Mobile Menu*/
.sticky{ 
	position: fixed;
  bottom: 0; 
  left: 0; 
  width: 100%;
  
}

/*New CSS for NFT Cards*/
#staked-nft-container {
  text-align: center;
  vertical-align: middle;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

#nftTD {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: center;
  vertical-align: middle;
}

/* #nftAttTD{

} */

.VaultName {
  font-size: 15px;
}

#table {
  align-items: center;
}

.nftLvl {
  font-size: 15px;
  color: black;
  font-family: "SF Pro Display"; 
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 100;
}


.nftChk {
  position: absolute;
  top: -5px;
  left: 150px;
  z-index: 100;
}

.nftImg {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 0%;
  width: 150px !important;  
}

.nftCard {
  height: 230px;
  width: 150px;
  position: relative;
  color: #ffffff;
  border: 10px solid var(--white-rgba);
  background: radial-gradient(var(--theme-color-1), var(--theme-color-2));
  box-shadow: 0px 1px 10px #ffffff;
}

.nftTxt1,
.nftTxt2,
.nftTxt3 {
  font-size: 10px;
}

.nftTxt1 {
  position: absolute;
  top: 67%;
  left: 10px;
}

.nftTxt2 {
  position: absolute;
  top: 74%;
  left: 10px;
}

.nftTxt3 {
  position: absolute;
  top: 82%;
  left: 40px;
  text-align: left;
} 

.font-awesome-icons::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  color: white;
  -webkit-font-smoothing: antialiased;
} 

.rocket::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f005";
  font-size: 13px; 
 /*  content: "\f135"; */
}

.nftBtn {
  position: absolute;
  top: 82%;
  left: 10px;
}

/* Nitro CSS */
/* Mobile*/
#prompt-form-mobile {
  width: 70% !important;
  height: 300px !important;
}

#prompt-message-mobile {
  overflow-y: scroll;
  overflow: scroll;
  width: 100% !important;
  height: 220px !important;
  display: grid;
  grid-template-columns: repeat(1, 4fr);
  grid-row-gap: .5em;
  grid-column-gap: 1em;
}

.nitCard-mobile {
  margin-left: 3px;
  padding: 1px;
  position: relative;
  background: radial-gradient(var(--theme-color-1), var(--theme-color-2));
}

/* Desktop*/
#prompt-form {
  width: 310px !important;
  height: 500px !important;
}


.scroll {
  overflow: scroll;
  height: 400px;
  width: 200px;
  padding-left: 1px;
  padding-right: 1px;
  display: grid;
  grid-template-columns: repeat(1, 4fr);
  grid-row-gap: .5em;
  grid-column-gap: 1em;
}

#prompt-message {
  height: 400px;
  width: 280px;
  /* 
  overflow-y: scroll;
  overflow: scroll;
  padding-left: 1px;
  padding-right: 1px;
  display: grid;
  grid-template-columns: repeat(1, 4fr);
  grid-row-gap: .5em;
  grid-column-gap: 1em; */
}

.nitTR {
  width: 100% !important;
}

.nitCard {
  margin-left: 10px;
  padding: 1px;
  position: relative;
  height: 77px !important;
  background: radial-gradient(var(--theme-color-1), var(--theme-color-2));
}


.nitTD1 {
  position: relative;
  background: radial-gradient(circle at 50% 50%, #090f11, #0c1519, #101e2b, #0f223c, #0e2144);
  width: 150px;
}

.nitTD2 {
  background-color: white;
  width: 50%;
  white-space: nowrap;
}

.nitAtt {
  padding-left: 7px;
  text-align: left;
  font-size: 10px;
}


.nitchk {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 100;
}

/*Vault Styles*/
.pendingRewards{
  display: inline;
  font-family: "SF Pro Display";
  font-weight: 300;
  color: rgb(242, 255, 0);
}

/* .td_block{
  display: block;
}

.tdth_min{
  padding-left: 16px;
  min-width: 170px;
  text-align: left; 
} */

/* Text Glow*/
.textGlow { 
  color: #fff; 
  -webkit-animation: textGlow 1s ease-in-out infinite alternate;
  -moz-animation: textGlow 1s ease-in-out infinite alternate;
  animation: textGlow 1s ease-in-out infinite alternate;
}
 

@keyframes textGlow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.vault_back_att{
  /* display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto; */
  padding: 10px;
}

.back_attr{ 
  padding: 1rem;
  margin-bottom: 1rem;
}
